@font-face {
  font-family: 'ClashDisplay';
  src: url('./assets/fonts/ClashDisplay_Complete/Fonts/Variable/ClashDisplay-Variable.ttf');
}

@font-face {
  font-family: 'lamaSans';
  src: url('./assets/fonts/Lama Sans/Fonts/Web/Standard/LamaSans-ExtraLight.woff');
  font-weight: 300;
}

@font-face {
  font-family: 'lamaSans';
  src: url('./assets/fonts/Lama Sans/Fonts/Web/Standard/LamaSans-Light.woff');
  font-weight: 400;
}

@font-face {
  font-family: 'lamaSans';
  src: url('./assets/fonts/Lama Sans/Fonts/Web/Standard/LamaSans-Medium.woff');
  font-weight: 500;
}

@font-face {
  font-family: 'lamaSans';
  src: url('./assets/fonts/Lama Sans/Fonts/Web/Standard/LamaSans-Bold.woff');
  font-weight: 600;
}

@font-face {
  font-family: 'lamaSans';
  src: url('./assets/fonts/Lama Sans/Fonts/Web/Standard/LamaSans-ExtraBold.woff');
  font-weight: 700;
}

@font-face {
  font-family: 'lamaSans';
  src: url('./assets/fonts/Lama Sans/Fonts/Web/Standard/LamaSans-Black.woff');
  font-weight: 800;
}
